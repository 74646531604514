




































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import gUserInfo from "../../../components/g-userInfo.vue";
import findWar from "../../../assets/js/WarArea";
import gHeader from "@/components/g-header.vue";
import { areaList } from "@vant/area-data";
@Component({
  components: {
    gUserInfo, //用户信息地址列表组件
    gHeader,
  },
  filters: {
    getFirst(str: string): string {
      return str ? JSON.parse(str)[0] : "";
    },
  },
})
export default class CVOrderDetail extends Vue {
  private isShowUserInfoList = false; // 是否展示用户信息列表
  private isShowActionBar = true; //是否展示底部操作栏
  private isShowwarAreaInformation = false; //是否展示战区信息
  private isEdtior = true; //是否是编辑
  private province = ""; //省份
  private name = ""; //姓名
  private phone = ""; //电话
  private city = ""; //市
  private address = ""; //详细地址
  private remark = ""; //备注
  private WarAreaProvince = ""; //战区省份
  private WarAreaInfo: any = ""; //备注
  private modelDetailList: Array<any> = []; //详情列表
  private totalOrderDetail = 0; //订单详情总价
  private orderId = ""; //订单id
  private title = "订单详情";
  private showUserInfo = false; // 是否展示地址列表
  private userInfo = {
    // 用户信息表单
    id: "",
    name: "",
    phone: "",
    provinceCity: "",
    address: "",
    remark: "",
  };
  private showArea = false;
  private areaList = areaList; //vant组件地区

  onConfirm(values: any) {
    //vant组件地区提交
    this.userInfo.provinceCity = values
      .filter((item: any) => !!item)
      .map((item: any) => item.name)
      .join("/");
    this.showArea = false;
  }
  goBack() {
    this.$router.back();
    // this.$router.push({ name: "CV-Home", params: { index: "order" } });
  }
  toDetail(id: any): void {
    //跳转到商品详情页
    this.$router.push({ name: "CV-Render3D", params: { id } });
    console.log("跳转到商品详情页");
  }
  modifyOrder(): void {
    // 修改订单按钮
    this.isEdtior = true; // 是否是编辑模式
    this.isShowUserInfoList = true;
    this.isShowActionBar = false;
  }
  showUserinfo() {
    //我的信息
    this.isEdtior = false; //非修改模式
    this.isShowUserInfoList = true;
  }
  getOrderDetail() {
    //获取订单详情
    this.$get("/big_home/get_order_detail", { id: this.orderId }).then(
      (res: any) => {
        if (res.data.status == 1) {
          let data = res.data.data;
          this.modelDetailList = data.model_list;
          // arr = [res.data.data];
          this.userInfo = {
            id: data.id,
            address: data.address,
            name: data.name,
            phone: data.phone,
            remark: data.remark,
            provinceCity: data.province + data.city,
          };

          this.WarAreaInfo = findWar(data.province); // 根据省份匹配战区、负责人、电话
          this.modelDetailList.forEach((v: any) => {
            this.totalOrderDetail += v.plank_num * 180 + v.plank_num * 100;
          });
        }
      }
    );
  }
  onSubmitUserInfo(val: any) {
    this.isShowActionBar = true;
    this.isShowUserInfoList = false;
    let provinceCity = val.provinceCity.split("/");
    this.province = provinceCity[0]; //省
    this.city = provinceCity[1]; //市
    this.name = val.name; //姓名
    this.phone = val.phone; //电话
    this.address = val.address; //详细地址
    this.remark = val.remark; //备注
    // 修改订单
    this.$post("/big_home/update_order", {
      id: this.orderId,
      address: this.address,
      phone: this.phone,
      name: this.name,
      city: this.city,
      province: this.province,
      remark: this.remark,
    }).then((res: any) => {
      if (res.data.status == 1) {
        this.$showToast("修改订单成功！");
      }
    });
  }
  closeUserInfo() {
    //关闭个人信息地址列表
    this.isShowActionBar = true;
    this.isShowUserInfoList = false;
  }
  closeAreaInformation() {
    //关闭联系客服
    this.isShowActionBar = true;
    this.isShowwarAreaInformation = false;
  }
  contactService() {
    //联系客服
    this.isShowActionBar = false;
    this.isShowwarAreaInformation = true;
    this.getOrderDetail();
  }
  mounted() {
    this.orderId = this.$route.params.id;
    this.getOrderDetail();
  }
}
